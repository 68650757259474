html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  background-color: #171717ff;
  background-size: cover;
}

#root{
  font-family: "Hind", sans-serif;
  font-weight: 500;
}

.alatsi-regular {
  font-family: "Alatsi", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.app-container {
  height: 100%;
}

.header-link:hover {
  text-decoration: none;
}

.header-link {
  text-decoration: none;
}

.navbar{
  position: fixed; 
  top: 0; 
  z-index: 1; 
  width: 100%; 
  overflow: hidden;
}

.title-section{
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh);
  position: relative;
  width: 100%;
}

.about-me-section{
  display: flex;
  justify-content: center;
  height: calc(100vh);
  width: 100%;
  padding-left: 10px;
  padding-right:10px;
}

.projects-section{
  display: flex;
  justify-content: center;
  /* height: calc(100vh); */
  width: 100%;
  padding-left: 10px;
  padding-right:10px;
  scroll-margin-top: 40px;
}

.experience-section{
  display: flex;
  justify-content: center;
  /* height: calc(100vh); */
  width: 100%;
  padding-left: 10px;
  padding-right:10px;
  scroll-margin-top: 100px;
}

/* .footer{
  width: 100%;
  overflow-x: hidden;
} */

.about-me-links {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: '20px';
}

@media (min-width: 992px) {
  .title-section,
  .about-me-section,
  .projects-section,
  .experience-section {
    padding-left: 40px;
    padding-right: 40px;
  }
}

