.carousel {
    
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    min-width: 375px;
}

.slide {
    border-radius: 0.1rem;
    box-shadow: 0px 0px 5px #666;
    width: 100%;
    height: 100%;
}

.slide-hidden {
    display: none;

}

.arrow {
    position: absolute;
    width: 2rem;
    height: 2rem;
    color: white;
    filter: drop-shadow(0px 0px 5px #555)
}

.arrow:hover {
  cursor: pointer;  
}

.arrow-left {
    left: 1rem;
}

.arrow-right {
    right: 1rem;
}

.indicators {
    display: flex;
    position: absolute;
    bottom: 1rem
}

.indicator {
    background-color: white;
    height: 0.5rem;
    width: 0.5rem;
    border-radius: 100px;
    border: none;
    outline: none;
    box-shadow: 0px 0px 5px #555;
    margin: 0 0.2rem;
    cursor: pointer;

}

.indicator-inactive {
    background-color: grey;
    height: 0.5rem;
    width: 0.5rem;
    border-radius: 100px;
    border: none;
    outline: none;
    box-shadow: 0px 0px 5px #555;
    margin: 0 0.2rem;
    cursor: pointer;
}