.projects-content {
    padding-left: 10px;
    padding-right: 10px;
}


a {
    color: white;
    text-decoration: none;
}

a:hover {
    color: white;
    text-decoration: underline;
}

.project-link {
    color: white;
    text-decoration: none;
    border: solid 1px white;
    padding: 2px 5px 2px 5px;
    margin-right: 10px;
}

.primary-list {
    list-style-type: disc;
}

.screenshot-carousel {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 75%;
    padding-bottom: 40px;
    margin: 0 auto; 
}

